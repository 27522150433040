<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refPartnerListTable"
        class="position-relative"
        :items="fetchPartner"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Keine passenden Einträge gefunden"
        style="min-height:200px"
      >
        <template #cell(bonusType)="data">
          <span v-if="data.item.bonusType === 1">Percentage</span>
          <span v-else-if="data.item.bonusType === 2">Fixed amount</span>
          <span v-else-if="data.item.bonusType === 3">Null</span>
        </template>

        <template #cell(bonusValue)="data">
          <span v-if="data.item.bonusType === 1">{{ data.item.bonusValue }} %</span>
          <span v-else-if="data.item.bonusType === 2">EUR {{ data.item.bonusValue }}</span>
          <span v-else-if="data.item.bonusType === 3">Null</span>
        </template>

        <template #cell(actions)="data">
          <b-link
            :to="{ name: 'partner-detail', params: { partnerId: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
            />
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {BCard, BLink, BTable} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import partnerStoreModule from '@/views/partner/partnerStoreModule'

export default {
  components: {
    BLink,
    BCard,
    BTable,
  },
  setup(props, ctx) {
    const toast = useToast()
    const PARTNER_APP_STORE_MODULE_NAME = 'app-partner'

    // Register module
    if (!store.hasModule(PARTNER_APP_STORE_MODULE_NAME)) store.registerModule(PARTNER_APP_STORE_MODULE_NAME, partnerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PARTNER_APP_STORE_MODULE_NAME)) store.unregisterModule(PARTNER_APP_STORE_MODULE_NAME)
    })

    const refPartnerListTable = ref(null)

    const tableColumns = [
      { key: 'title', label: 'Title', thStyle: { width: '50px !important' } },
      { key: 'key', label: 'Key', thStyle: { width: '50px !important' } },
      { key: 'bonusType', label: 'Bonusart', thStyle: { width: '50px !important' } },
      { key: 'bonusValue', label: 'Bonuswert', thStyle: { width: '50px !important' } },
      { key: 'actions', label: 'Aktionen', thStyle: { width: '80px !important' } },
    ]

    const fetchPartner = (ctx, callback) => {
      store
        .dispatch(`${PARTNER_APP_STORE_MODULE_NAME}/fetchPartner`, {})
        .then(response => {
          callback(response.data)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const refetchData = () => {
      refPartnerListTable.value.refresh()
    }

    return {
      tableColumns,
      refPartnerListTable,
      refetchData,
      fetchPartner,
    }
  },
}
</script>
