import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPartner(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/list', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPartnerDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/partner/get-partner/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    savePartner(ctx, {
      id, partnerData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/partner/update-partner/${id}`, JSON.parse(JSON.stringify(partnerData.value)))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
